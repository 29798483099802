import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleVideo, fragmentModuleInternalVideo } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockLogoBar, fragmentBlockMediaCardGroup, fragmentBlockQuoteGroup } from '../../../core/data-layer/blocks/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentBlockMediaCardGroup}
  ${fragmentBlockQuoteGroup}
  ${fragmentBlockLogoBar}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersCareerTypesDetailCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        description
        careerType
        headingTitle
        headingMedia {
          ...ModuleMediaFragment
        }
        headingVideo {
          ...on ModuleVideo {
            ...ModuleVideoFragment
          }
          ... on ModuleInternalVideo {
            ...ModuleInternalVideoFragment
          }
        }
        headingDescription {
          json
        }
        headingTestimonials {
          ...BlockMediaCardGroupFragment
        }
        testimonials {
          ... on BlockMediaCardGroup {
            ...BlockMediaCardGroupFragment
          }
          ... on BlockQuoteGroup {
            ...BlockQuoteGroupFragment
          }
        }
        logoBar {
          ...BlockLogoBarFragment
        }
      }
    }
  }
`
