import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'

export default gql`
  ${fragmentModuleMedia}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersLocationsCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        description
        subtitle
        locationsTitle
        headingMedia {
          ...ModuleMediaFragment
        }
      }
    }
  }
`
