import { gql } from 'graphql-tag'
import { fragmentModuleUsp } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockFaqGroup } from '../../../core/data-layer/blocks/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'

export default gql`
  ${fragmentModuleUsp}
  ${fragmentBlockFaqGroup}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersFaqsCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        description
        stepsCollection {
          items {
            ...ModuleUspFragment
          }
        }
        faq {
          ...BlockFaqGroupFragment
        }
      }
    }
  }
`
