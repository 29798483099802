import { gql } from 'graphql-tag'

export default gql`
  query($locale: String!, $preview: Boolean!) {
    page: pageCareersLocationsDetailCollection(locale: $locale, preview: $preview) {
      items {
        sys {
          id
        }
        city {
          name
          country {
            region {
              name
              uniqueId
            }
          }
        }
      }
    }
  }
`
