import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleUsp, fragmentModuleVideo, fragmentModuleInternalVideo } from '../../../core/data-layer/modules/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
import { fragmentGlobalDataCity, fragmentGlobalDataOffice } from '../../../core/data-layer/global-data/fragments'
import { fragmentBlockQuoteGroup, fragmentBlockContent } from '../../../core/data-layer/blocks/fragments'

export default gql`
  ${fragmentModuleMedia}
  ${fragmentModuleVideo}
  ${fragmentModuleInternalVideo}
  ${fragmentModuleUsp}
  ${fragmentBlockQuoteGroup}
  ${fragmentBlockContent}
  ${fragmentGlobalDataCity}
  ${fragmentGlobalDataOffice}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersLocationsDetailCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        description
        headingMedia {
          ...ModuleMediaFragment
        }
        headingVideo {
          ...on ModuleVideo {
            ...ModuleVideoFragment
          }
          ... on ModuleInternalVideo {
            ...ModuleInternalVideoFragment
          }
        }
        city {
          ...GlobalDataCityFragment
        }
        office {
          ...GlobalDataOfficeFragment
        }
        testimonials {
          ...BlockQuoteGroupFragment
        }
        locationFactsCollection {
          items {
            ...ModuleUspFragment
          }
        }
        perksTitle
        perksCollection {
          items {
            ...ModuleUspFragment
          }
        }
        techHub {
          ...BlockContentFragment
        }
      }
    }
  }
`
