import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink } from '../../../core/data-layer/modules/fragments'
import { fragmentBlockCompanyFormula } from '../../../core/data-layer/blocks/fragments'
import queryPageFields from '../../../core/data-layer/pages/page/query-page-fields'
/*
 This page is not retrieved in the async data but in the globalData
 It retrieves a set of default data for the job routes
 */

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentBlockCompanyFormula}
  query($slug: String!, $locale: String!, $preview: Boolean!) {
    page: pageCareersVacanciesDetailCollection(limit: 1, where: { slug: $slug }, locale: $locale, preview: $preview) {
      items {
        ${queryPageFields}
        contentType: __typename
        socialShareLabel
        backLink {
          ...ModuleNavigationLinkFragment
        }
        relatedJobsLink {
          ...ModuleNavigationLinkFragment
        }
        companyFormulaLink {
          ...ModuleNavigationLinkFragment
        }
        companyFormula {
          ...BlockCompanyFormulaFragment
        }
      }
    }
  }
`
